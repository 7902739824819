import useAxiosPrivate from "../Hooks/useAxiosPrivate";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from 'react-select';
import { useState } from 'react';

const UPLOAD_FILE_URL = '/singleUpload';
const CONTACT_URL = '/mail/feedback';
const ADD_ALUM_URL = '/alum/add';

function AlumniFeedback({img}) {
    const axiosPrivate = useAxiosPrivate();
    const [senderName, setSenderName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [senderPhone, setSenderPhone] = useState('');
    const [senderClass, setSenderClass] = useState('');
    const [question1, setQuestion1] = useState('');
    const [question2, setQuestion2] = useState('');
    const [question3, setQuestion3] = useState('');
    const [question4, setQuestion4] = useState('');
    const [question5, setQuestion5] = useState('');
    const [question6, setQuestion6] = useState('');
    const [file, setFile] = useState(null);

    let navigate = useNavigate();

    const date = new Date();
    const year = date.getFullYear();
    let classYear = [];

    for (var i = 2002; i <= year-1; i++) {
        classYear.push({label: i, value: i})
    }

    const sendEmail = async (e) => {
        e.preventDefault();
        const alumDetails = question1 + '<br><br>' + question2 + '<br><br>' + question3 + '<br><br>' + question4 + '<br><br>' + question5 + '<br><br>' + question6;
        const alumPost = { name:senderName, email:senderEmail, phone:senderPhone, classOf:senderClass.value, alumDetails };
        const contactMsg = { senderName, senderEmail, senderPhone, classOf: senderClass.value, question1, question2, question3, question4, question5, question6 };

        try {
            if(file){    
                if (!file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG)$/)) {
                  alert("Wrong file type. Upload jpg, jpeg or png");
                  navigate(0);
                }else if (file.size > 700000) {
                  alert("File is too large. Upload a file less than 700kb");
                  navigate(0);
                }else { 
                  const formData = new FormData();
                  const filename = senderName + '_' + Date.now() + '_' + file.name;
                  
                  formData.append("name", filename);
                  formData.append("file", file);
                  alumPost.picture = filename;
          
                  try{
                    await axiosPrivate.post(UPLOAD_FILE_URL, formData);
                  }catch(err){
                    console.log(err);
                  }
          
                  try {
                    await axiosPrivate.post(ADD_ALUM_URL, alumPost).then((response)=>{
                        alert('Message Sent');
                        navigate('/sent');
                    }).catch(function(error){
                    console.log(error);
                    });
                    await axiosPrivate.post(CONTACT_URL, contactMsg).then((response)=>{
                        alert('Message Sent');
                        navigate('/sent');
                    }).catch(function(error){
                      console.log(error.config);
                    });   
                  }catch (error) {
                    console.log(error);  
                  }
                }
            } else {
                alert('You have not included recent picture of you')
                navigate(0);
            }
        }catch (error) {
            console.log(error);  
        }
    }

    return (
        <>
            <section id="page-banner" className="pt-80 pb-50 bg_cover" data-overlay="8"  style={{backgroundImage: `url(${img}contactBanner.jpg)`}}>
                <div className="container">
                    <div className="page-banner-cont">
                        <h2>Alumni Testimonials</h2>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Alumni Testimonials</li>
                            </ol>
                        </nav>
                    </div>  { /* <!-- page banner cont --> */}
                </div> { /* <!-- container --> */}
            </section>
            <section id="contact-page" className="pb-30 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="contact-from mt-30">
                                <div className="section-title">
                                    <h5>testimonials</h5>
                                    <h2>Tell us your Story</h2>
                                </div> { /* <!-- section title --> */ }
                                <div className="main-form pt-45">
                                    <form id="contact-form" onSubmit={sendEmail} data-toggle="validator">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {file && (
                                                    <div className="singel-teachers mt-20 text-center">
                                                        <div className="image" style={{height: '277px'}}>
                                                            <img src={URL.createObjectURL(file)} alt="" />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="singel-form form-group">
                                                    <label htmlFor="fileInput">
                                                        {file ? <h5><i className="fa fa-recycle" /> Change Image</h5> : <h5><i className="fa fa-image" /> Add Image</h5>}
                                                    </label>
                                                    <input type="file" id="fileInput" style={{display: "none"}} onChange={(e)=>setFile(e.target.files[0])} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="singel-form form-group">
                                                    <input name="senderName" type="text" placeholder="Your name" data-error="Name is required." required="required" onChange={(e)=>setSenderName(e.target.value)}/>
                                                    <div className="help-block with-errors"></div>
                                                    <br />
                                                    <Select placeholder="Year of Exit/PLE" options={classYear} onChange={(e)=>setSenderClass(e)} />
                                                    <div className="help-block with-errors"></div>
                                                    <br />
                                                    <input name="senderEmail" type="email" placeholder="Contact Email" data-error="Valid email is required." required="required" onChange={(e)=>setSenderEmail(e.target.value)} />
                                                    <div className="help-block with-errors"></div>
                                                </div> { /* <!-- singel form --> */ }
                                                <div className="form-group pt-4">
                                                    <PhoneInput inputProps={{width: '100%', maxLength: 16, required: true}} className='mt-1' country={'ug'} value={senderPhone} onChange={setSenderPhone} />
                                                    <div className="help-block with-errors"></div>
                                                </div> { /* <!-- singel form --> */ }
                                            </div>
                                        </div>
                                        <div>
                                            <div className="singel-form form-group section-title">
                                                <br /><h5>1. Tell our readers how long you studied at Kampala Junior Academy.</h5>
                                                <textarea name="question1" placeholder="Message" className="form-control p-2" rows="10" data-error="Please,leave us a message." required="required" onChange={(e)=>setQuestion1(e.target.value)}></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div> 
                                        </div>{/** Sender Message */}
                                        <div>
                                            <div className="singel-form form-group section-title">
                                                <br /><h5>2. What are your most memorable moments at Kampala Junior Academy?</h5>
                                                <textarea name="question2" placeholder="Message" className="form-control p-2" rows="10" data-error="Please,leave us a message." required="required" onChange={(e)=>setQuestion2(e.target.value)}></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div> 
                                        </div>{/** Sender Message */}
                                        <div>
                                            <div className="singel-form form-group section-title">
                                                <br /><h5>3. Which interests and passions did you pursue through the clubs at Kampala Junior Academy and do you still pursue today?</h5>
                                                <textarea name="question3" placeholder="Message" className="form-control p-2" rows="10" data-error="Please,leave us a message." required="required" onChange={(e)=>setQuestion3(e.target.value)}></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div> 
                                        </div>{/** Sender Message */}
                                        <div>
                                            <div className="singel-form form-group section-title">
                                                <br /><h5>4. Describe your experience leaving Kampala Junior Academy. How did you feel about starting your new journey?</h5>
                                                <textarea name="question4" placeholder="Message" className="form-control p-2" rows="10" data-error="Please,leave us a message." required="required" onChange={(e)=>setQuestion4(e.target.value)}></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div> 
                                        </div>{/** Sender Message */}
                                        <div>
                                            <div className="singel-form form-group section-title">
                                                <br /><h5>5. Where are you today? Have you graduated, started a new job, visited a new place, got married, expanded your family, etc.? Tell us about it here!</h5>
                                                <textarea name="question5" placeholder="Message" className="form-control p-2" rows="10" data-error="Please,leave us a message." required="required" onChange={(e)=>setQuestion5(e.target.value)}></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div> 
                                        </div>{/** Sender Message */}
                                        <div>
                                            <div className="singel-form form-group section-title">
                                                <br /><h5>6. What advice would you give future Kampala Junior Academy students?</h5>
                                                <textarea name="question6" placeholder="Message" className="form-control p-2" rows="10" data-error="Please,leave us a message." required="required" onChange={(e)=>setQuestion6(e.target.value)}></textarea>
                                                <div className="help-block with-errors"></div>
                                            </div> 
                                        </div>{/** Sender Message */}
                                        <div>
                                            <div className="singel-form form-group section-title">
                                                <br />
                                                <h6>Disclaimer:</h6> 
                                                <p>
                                                    We would like to use your contact details to get in touch with you in the future about alumni events and activities and we would like to share your alumni stories in any of our publications.<br />However, this information will not be published or utilized without your consent. We will follow up with you directly to create a story that may be shared with other students or alumni. Thank you for taking the time to share your story.
                                                </p>
                                            </div> 
                                        </div>{/** Sender Message */}
                                        <p className="form-message"></p>
                                        <div>
                                            <div className="singel-form">
                                                <button type="submit" className="main-btn disabled" disabled={!senderName || !senderClass || !senderEmail || !senderPhone || !question1 || !question2 || !question3 || !question4 || !question5 || !question6}>Send</button>
                                                &nbsp;&nbsp;&nbsp;
                                                <Link className="main-btn" to='/'>Cancel</Link> 
                                            </div> { /* <!-- singel form --> */ }
                                        </div> 
                                    </form>
                                </div> { /* <!-- main form --> */ }
                            </div> { /* <!--  contact from --> */ }
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-address mt-30">
                                <ul>
                                    <li>
                                        <div className="singel-address">
                                            <div className="icon">
                                                <i className="fa fa-home"></i>
                                            </div>
                                            <div className="cont">
                                                <p>Plot 6A Channel, Yusuf Lule Road, Kampala</p>
                                            </div>
                                        </div> { /* <!-- singel address --> */ }
                                    </li>
                                    <li>
                                        <div className="singel-address">
                                            <div className="icon">
                                                <i className="fa fa-phone"></i>
                                            </div>
                                            <div className="cont">
                                                <p>+256 392 700 370</p>
                                            </div>
                                        </div> { /* <!-- singel address --> */ }
                                    </li>
                                    <li>
                                        <div className="singel-address">
                                            <div className="icon">
                                                <i className="fa fa-envelope-o"></i>
                                            </div>
                                            <div className="cont">
                                                <Link to='#email'>info@kja.ug</Link>
                                            </div>
                                        </div> { /* <!-- singel address --> */ }
                                    </li>
                                </ul>
                            </div> { /* <!-- contact address --> */ }
                            <div className="map mt-30">
                                <div id="contact-map"></div>
                            </div> { /* <!-- map --> */ }
                        </div>
                    </div> { /* <!-- row --> */ }
                </div> { /* <!-- container --> */ }
            </section>
        </>
    )
}

export default AlumniFeedback