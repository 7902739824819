import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import RightSideBar from "../../Components/RightSideBar"
import {Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NewsPageBanner from "./NewsPageBanner";
import Pagination from '../../Components/Pagination';

const VIEW_NEWS_URL = '/news';

function Stories({img, cat}) {
    const axiosPrivate = useAxiosPrivate();

    const [allStories, setAllStories] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [topStory, setTopStory] = useState({});
    const [page, setPage] = useState(1);
    const navigate = useNavigate();  
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_NEWS_URL + '?page=' + page + '&cat=' + cat).then((response) => {
            if(isMounted){
                setTopStory(response.data.topStory);
                setAllStories(response.data.docs);
                setTotalPages(response.data.pages);
            } 
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [page]);

    return (
        <>
            <NewsPageBanner img={img} cat={cat}/>
            <section id="blog-page" className="pb-30 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            { topStory?.id ?
                                <> 
                                    <div className="singel-blog mt-30" onClick = { ()=> navigate(`/news/view/${topStory.id}`)} style={{cursor: 'pointer'}}>
                                        <div className='blog-thum'>
                                            <img src={img + topStory.picture.split(',')[0]} alt="" />
                                        </div>
                                        <div className="blog-cont">
                                            <Link to={`/news/view/${topStory.id}`}><h4>{topStory.title}</h4></Link>
                                            <ul>
                                                <li><Link to="#date"><i className="fa fa-calendar"></i>{new Date(topStory.createdAt).toDateString()}</Link></li>
                                            </ul>
                                            <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: topStory.detail}} />
                                        </div>
                                    </div>                            
                                    <div className="row">
                                        { allStories.map((value)=>{
                                            return (
                                                <div key={value.id} className='mt-30 col-md-6'>
                                                    <div className='singel-blog' onClick={() => navigate(`/news/view/${value.id}`)} style={{cursor: 'pointer'}}>
                                                        <div className='blog-thum overflow-hidden' style={{height:'200px'}}>
                                                            <img className="img-fluid w-100 h-100" src={img + value.picture.split(',')[0]} alt="" style={{objectFit: 'cover'}} loading='lazy' />
                                                        </div>
                                                        <div className="blog-cont">
                                                            <Link to={`/news/view/${value.id}`}><h4>{value.title}</h4></Link>
                                                            <ul>
                                                                <li><Link to="#date"><i className="fa fa-calendar"></i>{new Date(value.createdAt).toDateString()}</Link></li>
                                                            </ul>
                                                            <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: value.detail}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                    </div>
                                    {totalPages>1 &&
                                        <div className="col-lg-12">
                                            <Pagination totalPages={totalPages} setPage={setPage} page={page} />
                                        </div>
                                    }
                                </>
                            :
                                <div className="singel-blog">
                                    <div className='contact-from mt-30 mb-300'>
                                        <div className="section-title">
                                            <h5>news</h5>
                                            <h2>News</h2>
                                        </div> { /* <!-- section title --> */ }
                                        <div className='blog-details pt-45 mb-200'>
                                            <p>There are no news stories at the moment.<br /><br /></p> <h6><Link to='/'>Return to the Home Page</Link></h6>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <RightSideBar img={img}/>
                    </div> { /* <!-- row --> */ }
                </div> { /* <!-- container --> */ }
            </section>
        </>
    )
}

export default Stories