import { Link, useNavigate, useLocation} from "react-router-dom";
import useLogout from '../Hooks/useLogout';
import useAuth from '../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

function Navbar({img}) {
    const { auth } = useAuth();
    const location = useLocation();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
    const from = location.state?.from?.pathname || `/`;
    const logOut = useLogout();
    let navigate = useNavigate();

    const signOut = async () => {  
        await logOut(); 
        navigate(from, { replace: true });
    }

    let activeLocation = location?.pathname.split('/').pop();
    let activeNav = '';
    
    if (activeLocation === 'about') {
        activeNav = 'about';
    } else if(activeLocation === 'principal') {
        activeNav = 'about';
    } else if(activeLocation === 'director') {
        activeNav = 'about';
    } else if(activeLocation === 'governors') {
        activeNav = 'about';
    } else if(activeLocation === 'management') {
        activeNav = 'about';
    } else if(activeLocation === 'teachers') {
        activeNav = 'about';
    }else if(activeLocation === 'admissions'){
        activeNav = 'about';
    } else if(activeLocation === 'apply'){
        activeNav = 'about';
    } else if(activeLocation === 'fees'){
        activeNav = 'about';
    } else if(activeLocation === 'academic'){
        activeNav = 'learning';
    } else if(activeLocation === 'arts'){
        activeNav = 'learning';
    } else if(activeLocation === 'sport'){
        activeNav = 'learning';
    } else if(activeLocation === 'leadership'){
        activeNav = 'learning';
    } else if(activeLocation === 'sports'){
        activeNav = 'clubs';
    } else if(activeLocation === 'music'){
        activeNav = 'clubs';
    } else if(activeLocation === 'language'){
        activeNav = 'clubs';
    } else if(activeLocation === 'adventure'){
        activeNav = 'clubs';
    } else if(activeLocation === 'talent'){
        activeNav = 'clubs';
    } else if(activeLocation === 'news'){
        activeNav = 'news';
    } else if(activeLocation === 'events'){
        activeNav = 'news';
    } else if(activeLocation === 'blog'){
        activeNav = 'news';
    } else if(activeLocation === 'gallery'){
        activeNav = 'news';
    } else if(activeLocation === 'alnews'){
        activeNav = 'alumni';
    } else if(activeLocation === 'alevents'){
        activeNav = 'alumni';
    } else if(activeLocation === 'alblog'){
        activeNav = 'alumni';
    } else if(activeLocation === 'algallery'){
        activeNav = 'alumni';
    } else if(activeLocation === 'alfeedback'){
        activeNav = 'alumni';
    } else if(activeLocation === 'allisting'){
        activeNav = 'alumni';
    } else if(activeLocation === 'contact'){
        activeNav = 'contact';
    } else if(activeLocation === 'vacancies'){
        activeNav = 'contact';
    }
    
    return (
        <>
            <header id="header-part">
                <div className="header-top d-none d-lg-block">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="header-contact text-lg-left text-center">
                                    <ul>
                                        <li>
                                            <img src={`${img}all-icon/map.png`} alt="icon" />
                                            <span>Plot 6A Channel, Yusuf Lule Road, Kampala</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="header-opening-time text-lg-right text-center">
                                    <div className='footer-about'>
                                        <ul>
                                            <li><a target="_blank" rel="noreferrer" href="https://facebook.com/kampalajunioracademyschool"><i className="fa fa-facebook-f"></i></a></li>
                                            <li><a target="_blank" rel="noreferrer" href="https://x.com/kjalearners"><i className="fa fa-twitter"></i></a></li>
                                            <li><a target="_blank" rel="noreferrer" href="https://youtube.com/@Kampalajunioracademy"><i className="fa fa-youtube-play"></i></a></li>
                                            <li><a target="_blank" rel="noreferrer" href="https://instagram.com/kampalajunioracademyofficial/"><i className="fa fa-instagram"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> {/* <!-- row --> */} 
                    </div> {/*  <!-- container --> */} 
                </div> {/*  <!-- header top --> */} 
                <div className="header-logo-support pt-10 pb-10">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-4">
                                <div className="logo">
                                    <Link to="/">
                                        <img src={`${img}logo.png`} alt="Logo" />
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-8">
                                <div className="support-button float-right d-none d-md-block">
                                    <div className="support float-left">
                                        <div className="icon">
                                            <img src={`${img}all-icon/support.png`} alt="icon" />
                                        </div>
                                        <div className="cont">
                                            <p>Need Help? Call us</p>
                                            <span>+256 392 700 370</span>
                                        </div>
                                    </div>
                                    <div className="button float-left">
                                        <Link to="/apply" className="main-btn">Enroll Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div> { /*  <!-- row --> */}
                    </div> { /*  <!-- container --> */}
                </div> { /*  <!-- header logo support --> */}
                {/* <div className="navigation"> */}
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-8 pt-10">
                                {/* <nav className="navbar navbar-expand-lg"> */}
                                <nav className="navbar navbar-expand-lg bg-red navbar-dark py-1 py-lg-0 px-lg-5">    
                                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                    <div className="collapse navbar-collapse justify-content-between px-0 px-lg-3" id="navbarSupportedContent">
                                        <div className="navbar-nav mr-auto py-0">
                                            <div className="nav-link dropdown">
                                                <Link to="#about" className={activeNav === 'about' ? 'dropdown-toggle active' : 'dropdown-toggle'} data-toggle="dropdown">About</Link>
                                                <div className="dropdown-menu rounded-0 m-0 p-0">
                                                    <Link className={activeLocation === 'about' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/about">About Us</Link>                                                  
                                                    <Link className={activeLocation === 'director' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/director">From the Director</Link>
                                                    <Link className={activeLocation === 'admissions' ? 'active' : 'dropdown-item p-2'} to="/admissions">Admission Process</Link>
                                                    <Link className={activeLocation === 'apply' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/apply">Apply Online</Link>
                                                    <Link className={activeLocation === 'fees' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/fees">Fees & Payments</Link>
                                                </div>
                                            </div>
                                            <div className="nav-link dropdown">
                                                <Link to="#campuses" className={activeNav === 'campuses' ? 'dropdown-toggle active' : 'dropdown-toggle'} data-toggle="dropdown">Campuses</Link>
                                                <div className="dropdown-menu rounded-0 m-0 p-0">
                                                    <Link className={activeLocation === 'kitante' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/kitante">Kitante</Link>
                                                    <Link className={activeLocation === 'najja' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/najja">Najjanankumbi</Link>
                                                    <Link className={activeLocation === 'makindye' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/makindye">Makindye</Link>
                                                    <Link className={activeLocation === 'bunga' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/bunga">Bunga</Link>
                                                    <Link className={activeLocation === 'ntinda' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/ntinda">Ntinda</Link>
                                                </div>
                                            </div>
                                            <div className="nav-link dropdown">
                                                <Link to="#learning" className={activeNav === 'learning' ? 'dropdown-toggle active' : 'dropdown-toggle'} data-toggle="dropdown">Learning</Link>
                                                <div className="dropdown-menu rounded-0 m-0 p-0">
                                                    <Link className={activeLocation === 'academic' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/academic">Academic</Link>
                                                    <Link className={activeLocation === 'arts' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/arts">Arts & Culture</Link>
                                                    <Link className={activeLocation === 'sport' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/sport">Sport</Link>
                                                    <Link className={activeLocation === 'leadership' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/leadership">Leadership & Service</Link>
                                                </div>
                                            </div>
                                            <div className="nav-link dropdown">
                                                <Link to="#clubs" className={activeNav === 'clubs' ? 'dropdown-toggle active' : 'dropdown-toggle'} data-toggle="dropdown">Clubs</Link>
                                                <div className="dropdown-menu rounded-0 m-0 p-0">
                                                    <Link className={activeLocation === 'sports' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/sports">Sports</Link>
                                                    <Link className={activeLocation === 'music' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/music">Music & Dance</Link>
                                                    <Link className={activeLocation === 'language' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/language">Language</Link>
                                                    <Link className={activeLocation === 'adventure' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/adventure">Leadership & Adventure</Link>
                                                    <Link className={activeLocation === 'religion' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/religion">Religion</Link>
                                                    <Link className={activeLocation === 'talent' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/talent">Other Skills & Talents</Link>
                                                </div>
                                            </div>
                                            <div className="nav-link dropdown">
                                                <Link to="#news" className={activeNav === 'news' ? 'dropdown-toggle active' : 'dropdown-toggle'} data-toggle="dropdown">Media</Link>
                                                <div className="dropdown-menu rounded-0 m-0 p-0">
                                                    <Link className={activeLocation === 'news' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/news">News</Link>
                                                    <Link className={activeLocation === 'events' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/events">Calendar</Link>
                                                    <Link className={activeLocation === 'blog' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/blog">Blog</Link>
                                                    <Link className={activeLocation === 'gallery' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/gallery">Gallery</Link>
                                                </div>
                                            </div>
                                            <div className="nav-link dropdown">
                                                <Link to="#alumni" className={activeNav === 'alumni' ? 'sub-nav-toggle active' : 'dropdown-toggle'} data-toggle="dropdown">Alumni</Link>
                                                <div className="dropdown-menu rounded-0 m-0 p-0">
                                                    <Link className={activeLocation === 'alnews' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/alumni/alnews">News</Link>
                                                    <Link className={activeLocation === 'alevents' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/alumni/alevents">Upcoming Events</Link>
                                                    <Link className={activeLocation === 'alblog' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/alumni/alblog">Our Stories</Link>
                                                    <Link className={activeLocation === 'algallery' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/alumni/algallery">Photo Gallery</Link>
                                                    <Link className={activeLocation === 'alfeedback' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/alumni/alumcontact">Get in Touch</Link>
                                                    <Link className={activeLocation === 'allisting' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/alum/">Alumni Profiles</Link>
                                                </div>
                                            </div>
                                            <div className="nav-link dropdown">
                                                <Link to="#contact" className={activeNav === 'contact' ? 'dropdown-toggle active' : 'dropdown-toggle'} data-toggle="dropdown">Contact</Link>
                                                <div className="dropdown-menu rounded-0 m-0 p-0">
                                                    <Link className={activeLocation === 'contact' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/contact">Contact Us</Link>
                                                    <Link className={activeLocation === 'apply' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/visit">Visit Us</Link>
                                                    <Link className={activeLocation === 'vacancies' ? 'active dropdown-item p-2' : 'dropdown-item p-2'} to="/vacancies">Staff Vacancies</Link>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </nav> { /* <!-- nav --> */}
                            </div> { /*  <!-- row --> */}
                            <div className="col-4">
                                <div className="right-icon text-right">
                                    <ul>
                                        {authDecode ? 
                                            <>
                                                <li><Link to="#logout"><i className="fa fa-sign-out" onClick={signOut}></i></Link></li>
                                                <li><Link to="/user"><i className="fa fa-user"></i></Link></li>
                                                {authDecode?.user?.role >=4 && 
                                                    <li><Link to="/admin"><i className="fa fa-cogs"></i></Link></li>                                        
                                                }
                                            </>
                                        : 
                                            <>
                                                <li><Link to="/login"><i className="fa fa-sign-in"></i></Link></li>                                       
                                            </>
                                        }
                                        <li><Link to="#search" id="search"><i className="fa fa-search"></i></Link></li>
                                    </ul>
                                </div> { /*  <!-- right icon --> */}
                            </div>
                        </div> { /*  <!-- container --> */}
                    </div>
                {/* </div>  */}
            </header>
        </>
    );
}

export default Navbar;