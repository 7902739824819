import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'

const USERS_URL = '/admin/users';

function Users() {
  const axiosPrivate = useAxiosPrivate();

  const [listAdmins, setListAdmins] = useState([]);  
  const [listEditors, setListEditors] = useState([]);  
  const [listAuthors, setListAuthors] = useState([]);  
  const [listUsers, setListUsers] = useState([]);  

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    axiosPrivate.get(USERS_URL).then((response)=>{
      if(isMounted){
        setListAdmins(response.data.admins);
        setListEditors(response.data.editors);
        setListAuthors(response.data.authors);
        setListUsers(response.data.users);
      }

    }).catch((error) => {
      //Handle Errors Coming out of this
    });

    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-8">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">REGISTERED USERS</h4>
      </div>
      { listAdmins?.length>0 && 
        <>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Administrators</b>
          </div>
          <div className="align-items-center mb-1 overflow-hidden p-3 row">
            { listAdmins?.map((item) => {
              return(
                <div key={item.id} className='col-lg-3 overflow-hidden align-items-left'>
                  <Link className="text-secondary" to={`/admin/users/${item.id}`}>
                    {item?.name ? item?.name : item?.email}
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      }
      { listEditors?.length>0 && 
        <>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Editors</b>
          </div>
          <div className="align-items-center mb-1 overflow-hidden p-3 row">
            { listEditors?.map((item) => {
              return(
                <div key={item.id} className='col-lg-3 overflow-hidden align-items-left'>
                  <Link className="text-secondary" to={`/admin/users/${item.id}`}>
                    {item?.name ? item?.name : item?.email}
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      }
      {listAuthors?.length>0 && 
        <>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Authors</b>
          </div>
          <div className="align-items-center mb-1 overflow-hidden p-3 row">
            { listAuthors?.map((item) => {
              return(
                <div key={item.id} className='col-lg-3 overflow-hidden align-items-left'>
                  <Link className="text-secondary" to={`/admin/users/${item.id}`}>
                    {item?.name ? item?.name : item?.email}
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      }
      { listUsers?.length>0 && 
        <>
          <div className="bg-white align-items-center mb-1 overflow-hidden p-3 border">
            <b>Regular Users</b>
          </div>
          <div className="align-items-center mb-1 overflow-hidden p-3 row">
            { listUsers?.map((item) => {
              return(
                <div key={item.id} className='col-lg-3 overflow-hidden align-items-left'>
                  <Link className="text-secondary" to={`/admin/users/${item.id}`}>
                    {item?.name ? item?.name : item?.email}
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      }
    </div>
  )
}

export default Users
