import axios from '../Assets/axios';
import useAuth from './useAuth';

const RERESH_URL = '/users/refresh';

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get(RERESH_URL, { withCredentials: true });
        setAuth(prev => {
            return { ...prev,  accessToken: response.data.accessToken,  }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;