/* eslint-disable jsx-a11y/anchor-has-content */
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { Link, useNavigate} from 'react-router-dom';
import { useState, useEffect } from 'react';
import useAuth from '../../Hooks/useAuth';
import {jwtDecode} from 'jwt-decode';
import Pagination from '../../Components/Pagination';

const VIEW_EVENTS_URL = '/events';
const DELETE_EVENT_URL = '/events/delete/';

function UpComingEvents({img, cat}) {
    const axiosPrivate = useAxiosPrivate();
    const { auth } = useAuth();
    const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  
    const [totalPages, setTotalPages] = useState(0);
    const [calEvents, setCalEvents] = useState([]);
    const [page, setPage] = useState(1);
    const month = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
    const navigate = useNavigate();
    
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_EVENTS_URL + '?page=' + page + '&cat=' + cat).then((response)=>{
        if(isMounted){
            setCalEvents(response.data.docs);
            setTotalPages(response.data.pages);
        }
      }).catch((error) => {
        //Handle Errors Coming out of this
    });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
    }, [page]);
  
  
    const deleteCalEvent = async(id) => {
      try {
        axiosPrivate.delete(DELETE_EVENT_URL + id).then(()=>{
          alert('Calendar Event Deleted');
          navigate('/events');
        });               
      } catch (error) {       
      }
    };

    return (
        <>
            { calEvents.length>0 ? 
                <div className='contact-from mt-30 mb-60'>
                    <div className="section-title mb-5">
                        <h5>events</h5>
                        <h2>Upcoming Events</h2>
                    </div> { /* <!-- section title --> */ }
                    { calEvents.map((calEvent) => {
                        return (
                            <div key={calEvent.id} className="singel-feature mt-30">
                                <div className='cont'>
                                    <div className="row">
                                        <div className='col-2 pt-1 events-address'>
                                            <p className='px-3'>{ month[new Date(calEvent.date).getMonth()]}</p>
                                            {(new Date(calEvent.date).getDate())>9 ? 
                                                <p className='date-h2'> {new Date(calEvent.date).getDate()}</p>
                                            :
                                                <p className='date-h2'> 0{new Date(calEvent.date).getDate()}</p>
                                            }
                                            <p className='px-2'>{ new Date(calEvent.date).getFullYear()}</p>
                                        </div>
                                        <div className="col-10 pt-2">
                                            <Link to={`/events/view/${calEvent.id}`}><h5>{calEvent.title}</h5></Link> &nbsp;&nbsp;
                                            {calEvent.location && <p><i className="fa fa-map-marker"></i> {calEvent.location}</p>}
                                            {(authDecode && (authDecode?.user?.id === calEvent.UserId || authDecode?.user?.role >=4)) &&   
                                                <span className='icon' onClick={() => deleteCalEvent(calEvent.id)} style={{cursor: 'pointer'}}><i className="fa fa-trash icon" aria-hidden="true"/> Delete</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })} {/** Upcoming Events */}
                    {totalPages>1 &&
                        <div className="col-lg-12">
                            <Pagination totalPages={totalPages} setPage={setPage} page={page} />
                        </div>
                    }
                </div>
            :
                <div className='contact-from mt-30 mb-60'>
                    <div className="section-title">
                        <h5>events</h5>
                        <h2>Upcoming Events</h2>
                    </div> { /* <!-- section title --> */ }
                    <div className='blog-details pt-45 mb-200'>
                        <p>There are no scheduled events at the moment.<br /><br /></p> <h6><Link to='/'>Return to the Home Page</Link></h6>
                    </div>
                </div>
            }
        </>
    )
}

export default UpComingEvents