import React from 'react';
import {Link} from 'react-router-dom';

function PageNotFound () {
    return (
      <>
        <section id="page-banner" className="pt-105 pb-80 bg_cover" data-overlay="8" style={{backgroundImage: 'url(images/page-banner-6.jpg)'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="page-banner-cont">
                  <h2>Page Not Found</h2>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Page Not Found</li>
                    </ol>
                  </nav>
                </div>  { /* { /*<!-- page banner cont --> */} 
              </div>
            </div> { /* { /*<!-- row --> */} 
          </div> { /* { /*<!-- container --> */}
        </section>
        <section id="teachers-part" className="pt-90 pb-120 gray-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className='contact-from mt-30 mb-300'>
                    <div className="section-title">
                        <h5>404</h5>
                        <h2>Page Not Found</h2>
                    </div> { /* <!-- section title --> */ }
                    <div className='blog-details pt-45 mb-200'>
                      <p>The resource you  are looking for is not available on this website. <Link to='/'>Return to the Home Page</Link></p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="happy-student mt-30">
                    <div className="happy-title">
                      <h3>Promotion</h3>
                    </div>
                    <div className="student-slied">
                        <div className="singel-student">
                            <img src="images/teachers/teacher-2/quote.png" alt="Quote" />
                            <p>Aliquetn sollicitudirem quibibendum auci elit cons equat ipsutis sem nibh id elit. Duis sed odio sit amet</p>
                            <h6>Mark Anthem</h6>
                            <span>Bsc, Engineering</span>
                        </div> { /*<!-- singel student --> */}
                    </div> { /*<!-- student slied --> */}
                    <div className="student-image">
                      <img src="images/teachers/teacher-2/happy.png" alt='' />
                    </div>
                  </div> { /*<!-- happy student --> */}
                </div>
              </div> { /*<!-- row --> */}
            </div> { /*<!-- container --> */}
        </section>
      </>

    )
}

export default PageNotFound