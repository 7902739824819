import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import useAuth from '../../Hooks/useAuth';
import { jwtDecode } from 'jwt-decode';

const VIEW_ADMIN_DASH_URL = '/admin/dash';

function Dashboard() {

  const { auth } = useAuth();
  const authDecode = auth?.accessToken ? jwtDecode(auth.accessToken) : undefined;
  const axiosPrivate = useAxiosPrivate();
  const [listOrders, setListOrders] = useState([]);  
  const [listUsers, setListUsers] = useState([]);  
  const [listNews, setListNews] = useState([]);  
  const [listArticles, setListArticles] = useState([]);  
  const [listProfiles, setListProfiles] = useState([]);  
  const [listGalleries, setListGalleries] = useState([]);  

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    if(authDecode){
      axiosPrivate.get(VIEW_ADMIN_DASH_URL).then((response)=>{
        if(isMounted){
          setListOrders(response.data.orders);
          setListUsers(response.data.users);
          setListNews(response.data.news);
          setListArticles(response.data.articles);
          setListProfiles(response.data.profiles);
          setListGalleries(response.data.galleries);
        }

      }).catch((error) => {
        //Handle Errors Coming out of this
      });
    }
    return () => { isMounted = false; controller.abort(); }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="col-lg-8 mb-3">
      <div className="section-title">
        <h4 className="m-0 text-uppercase font-weight-bold">Dashboard</h4>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="position-relative mb-3">
            <div className="bg-white border border-top-0 p-2">
              <Link className="h6 d-block mb-3 text-secondary text-uppercase font-weight-bold" to={`/admin/orders`}>Lastest Orders</Link>
              <hr />
              { listOrders?.length>0 && listOrders?.map((item) => {
                return(
                  <div key={item.id}>
                    <small className="d-block font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}>
                      {new Date(item.createdAt).toDateString()}
                    </small>
                    <Link className="h6 text-secondary font-weight-bold mr-2 p-1" to={`/admin/orders/${item?.orderCode}`}>{item?.orderCode}:</Link> 
                    <small className="badge badge-primary text-uppercase font-weight-bold p-1 mr-2 mb-3">{item.amount.toLocaleString('en')}/-</small>
                    &nbsp; [{item.mmCode ? <>{item.dispatched  ? <>Paid. Dispatched</> : <>Paid. No Dispatch</>}</> : <>Not Paid</>}]
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="position-relative mb-3">
            <div className="bg-white border border-top-0 p-2">
              <Link className="h6 d-block mb-3 text-secondary text-uppercase font-weight-bold" to={`/admin/users`}>New Users</Link>
              <hr />
              { listUsers?.length>0 && listUsers?.map((item) => {
                return(
                  <div key={item.id}>
                    <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}>
                      {new Date(item.createdAt).toDateString()}
                    </small>
                    <Link className="h6 d-block text-secondary font-weight-bold mr-2 p-1 mb-3" to={`/admin/users/${item.id}`}>
                      {item?.name ? item?.name : item?.email }
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="section-title">
        <h4 className="m-0 font-weight-bold">Lastest Content</h4>
      </div>
      <div className="bg-white border border-top-0 p-2">
        <h4>News</h4>
        <hr />
        {listNews?.length>0 && listNews?.map((item) => {
          return(
            <div key={item.id}>
              <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}>
                {new Date(item.createdAt).toDateString()}
              </small> 
              <Link className="h6 d-block mb-3 text-secondary font-weight-bold p-1 mr-2" to={`/news/view/${item.id}`}>
                {item?.title}
              </Link>
            </div>
          )
        })}
        <br />
        <h4>Articles</h4>
        <hr />
        {listArticles?.length>0 && listArticles?.map((item) => {
          return(
            <div key={item.id}>
              <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}>
                {new Date(item.createdAt).toDateString()}
              </small>
              <Link className="h6 d-block mb-3 text-secondary font-weight-bold mr-2 p-1" to={`/articles/view/${item.id}`}>
                {item?.title}
              </Link>
            </div>
          )
        })}
        <br />
        <h4>Profiles</h4>
        <hr />
        {listProfiles?.length>0 && listProfiles?.map((item) => {
          return(
            <div key={item.id}>
              <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}>
                {new Date(item.createdAt).toDateString()}
              </small>
              <Link className="h6 d-block mb-3 text-secondary font-weight-bold mr-2 p-1" to={`/profiles/view/${item.id}`}>
                {item?.profileTitle}
              </Link>
            </div>
          )
        })}
        <br />
        <h4>Galleries</h4>
        <hr />
        {listGalleries?.length>0 && listGalleries?.map((item) => {
          return(
            <div key={item.id}>
              <small className="font-weight-semi-bold p-1 mr-2" style={{width: '115px'}}>
                {new Date(item.createdAt).toDateString()}
              </small>
              - 
              <Link className="d-block mb-3 text-secondary font-weight-bold mr-2 p-1" to={`/gallery/view/${item.id}`}>
                {item?.name}
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Dashboard
