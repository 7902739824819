import {Link } from 'react-router-dom';
import { useState } from 'react'

const Pagination = ({totalPages, setPage, page}) => {
    const [pageSet, setPageSet] = useState(0);
    
    let pages = [];
    const pageSets = Math.ceil(totalPages/5); //Identify how many sets of 5 to display
    for(let i = (pageSet*5); i<totalPages; i++){ //determine page numbers to display when << previous or >> next buttons are clicked
        pages.push(i+1); 
    }

    console.log('Total Pages: ' + totalPages + ', PageSets: ' + pageSets)
    console.log(pages)

    return (
        <nav className="courses-pagination mt-50">
            <ul className="pagination justify-content-center">
                {pageSet>0 &&
                    <li className="page-link" onClick={(e)=>{setPageSet(pageSet-1)}}>
                        <Link to="#previous" aria-label="Previous">
                            <i className="fa fa-angle-left"></i>
                        </Link>
                    </li>                                                
                }
                {
                    pages.slice(0,5).map((pageNum, index) => {return <li className="page-item" key={index}><Link className={page===pageNum ? 'active' : ''} to={`#page=${pageNum}`} onClick={(e)=>setPage(pageNum)}>{pageNum}</Link></li>})
                }
                {(pageSet+1)<pageSets && 
                    <li className="page-link" onClick={(e)=>{setPageSet(pageSet+1)}}>
                        <Link to="#next" aria-label="Next">
                            <i className="fa fa-angle-right"></i>
                        </Link>
                    </li>                                                    
                }

            </ul>
        </nav>
    )
}

export default Pagination
