import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import RightSideBar from '../../Components/RightSideBar';
import ArticlePageBanner from './ArticlePageBanner';
import {Link, useNavigate} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Pagination from '../../Components/Pagination';

const VIEW_ARTICLES_URL = '/articles';

function ViewArticles({img, cat}) {
    const axiosPrivate = useAxiosPrivate();
    const [allArticles, setAllArticles] = useState([]);
    const [topArticle, setTopArticle] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
  
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_ARTICLES_URL + '?page=' + page + '&cat=' + cat).then((response) => {
            if(isMounted){
                setTopArticle(response.data.main);
                setAllArticles(response.data.docs);
                setTotalPages(response.data.pages);
            } 
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort(); }
        // eslint-disable-next-line
    }, [page]);

    return (
        <>
            <ArticlePageBanner img={img} cat={cat}/>
            <section className='pb-30 gray-bg'>
                <div className="container">
                    <div className='row'>
                        <div className="col-lg-8 mb-3">
                            { topArticle?.id ? 
                                <>
                                    <div className="singel-blog mt-30" onClick={() => navigate(`/blog/view/${topArticle.id}`)} style={{cursor: 'pointer'}}>
                                        <div className='blog-thum overflow-hidden' style={{height:'200px'}}>
                                            <img className="img-fluid w-100 h-100" src={ img + topArticle?.picture.split(',')[0]} alt="" style={{objectFit: 'cover'}} loading='lazy' />
                                        </div>
                                        <div className="blog-cont">
                                            <Link to={`/blog/view/${topArticle?.id}`}><h4>{topArticle?.title}</h4></Link>
                                            <ul>
                                                <li><Link to="#date"><i className="fa fa-calendar"></i>{new Date(topArticle?.createdAt).toDateString()}</Link></li>
                                            </ul>
                                            <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: topArticle?.detail}} />
                                        </div>
                                    </div>
                                                                    
                                    <div className="row">
                                        {allArticles.map((value)=>{
                                            return (
                                                <div className="mt-30 col-md-6" key={value.id}>
                                                    <div className='singel-blog' onClick={() => navigate(`/blog/view/${value.id}`)} style={{cursor: 'pointer'}}>
                                                        <div className='blog-thum'>
                                                            <img src={img + value.picture.split(',')[0]} alt="" />
                                                        </div>
                                                        <div className="blog-cont">
                                                            <Link to={`/blog/view/${value.id}`}><h4>{value.title}</h4></Link>
                                                            <ul>
                                                                <li><Link to="#date"><i className="fa fa-calendar"></i>{new Date(value.createdAt).toDateString()}</Link></li>
                                                            </ul>
                                                            <p className="m-0 paraLimit" dangerouslySetInnerHTML={{ __html: value.detail}} />
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {totalPages>1 &&
                                        <div className="col-lg-12">
                                            <Pagination totalPages={totalPages} setPage={setPage} page={page} />
                                        </div>
                                    }
                                </>
                            :
                                <div className="singel-blog">
                                    <div className='contact-from mt-30 mb-300'>
                                        <div className="section-title">
                                            <h5>blog</h5>
                                            <h2>Blog Posts</h2>
                                        </div> { /* <!-- section title --> */ }
                                        <div className='blog-details pt-45 mb-200'>
                                            <p>There are no blog posts at the moment.<br /><br /></p> <h6><Link to='/'>Return to the Home Page</Link></h6>
                                        </div>
                                    </div>
                                </div>                            
                            }
                        </div>
                        <RightSideBar img={img} />
                    </div>
                </div>
            </section>
        </>

    )
}

export default ViewArticles