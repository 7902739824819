import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import AlumPageBanner from './AlumPageBanner';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Pagination from '../../Components/Pagination';

const VIEW_ALUMNI_URL = '/alum/';

function AlumProfiles({img}) {
    const [itemsPerPage, setItemsPerPage] = useState(30);
    const [searchQuery, setSearchQuery] = useState('0');
    const [totalPages, setTotalPages] = useState(0);
    const [alumni, setAlumni] = useState([]);
    const axiosPrivate = useAxiosPrivate();
    const [year, setYear] = useState(0);
    const [page, setPage] = useState(1);
    
    useEffect(() => {
        let isMounted = true;
        const controller = new AbortController();
        axiosPrivate.get(VIEW_ALUMNI_URL + '?page=' + page + '&year='+ year + '&q='+ searchQuery + '&items=' + itemsPerPage).then((response) => {
            if(isMounted){
                setAlumni(response.data.allAlums);
                setTotalPages(response.data.totalPages);
            }
        }).catch((error) => {
            //Handle Errors Coming out of this
        });
        return () => { isMounted = false; controller.abort();  }
        // eslint-disable-next-line
    }, [page, itemsPerPage, year, searchQuery]);

    const date = new Date();
    const years = date.getFullYear();
    let classYear = [];

    for (var i = 2002; i <= years-1; i++) {
        classYear.push({label: i, value: i})
    }

    return (
        <>
            <AlumPageBanner img={img}/>           
            <section className='pt-40 pb-120 gray-bg'>
                <div className="container">
                    <div className='pb-30 row'>
                        <div className="col-lg-3 row">
                            <div className='p-2'>Records Per Page:</div>
                            <div style={{width: '60px'}}> 
                                <select name="itemsPerPage" className='form-control' value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)}>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="480">500</option>
                                </select> 
                            </div>
                        </div>
                        <div className='col-lg-3 row'>
                            <div className='p-2'>Class Of:</div>
                            <div><Select placeholder="Year of Exit/PLE" options={classYear} onChange={(e)=>setYear(e.value)} />
                            </div>
                        </div>
                        <div className="col-lg-6 row">
                            <div className='p-2'>Search:</div>
                            <div className='p-2'>
                                <input name="searchQuery" type="text" placeholder="Type name here..." data-error="Name is required." required="required" onChange={(e)=>setSearchQuery(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {alumni.length>0 && alumni.map((value)=>{
                            return (
                                <div className="col-lg-2 col-md-4 singel-teachers mb-2" key={value.id}>
                                    <div className='row'>
                                        <div className='col-3 p-1 events-address image' style={{height: '60px'}}>
                                            <img className='img-fluid h-100' src={img + value.picture} style={{objectFit: "cover"}} alt="Alumus"/>
                                        </div>
                                        <div className='col-9'>
                                            <Link to={`/alum/view/${value.id}`}>{value.name}</Link><br/>
                                            {value.classOf}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>{/* row with profile cards*/}
                    {totalPages>1 &&
                        <div className="col-lg-12">
                            <Pagination totalPages={totalPages} setPage={setPage} page={page} />
                        </div>
                    }
                </div>
            </section>
        </>
    )
}

export default AlumProfiles