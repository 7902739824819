//import EventsList from './EventsList';
import GalleryHighlights from './GalleryHighlights';
import PagesIntro from './PagesIntro';

function About({img}) {

  return (
    <>
      <section>
        <div className="container pb-30">
          <div className="row">
            <PagesIntro/>
            <GalleryHighlights img={img} />
          </div> 
        </div>
      </section>
    </>
  )
}

export default About