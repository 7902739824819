import { useContext, useDebugValue } from "react";
import AuthContext from '../Assets/AuthProvider'

const useAuth = () => {
    const { auth } = useContext(AuthContext);
    useDebugValue(auth, auth => auth?.user?.name ? "Logged In" : "Logged Out")

    return useContext(AuthContext);

}


export default useAuth;