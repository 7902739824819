/* eslint-disable jsx-a11y/anchor-has-content */
import useAxiosPrivate from '../../Hooks/useAxiosPrivate';
import { useState, useEffect } from 'react';
import { Link} from 'react-router-dom';
import Pagination from '../../Components/Pagination';

const VIEW_EVENTS_URL = '/events/past';

function PastEvents({img, cat}) {
    const axiosPrivate = useAxiosPrivate();

    const [totalPages, setTotalPages] = useState(0);
    const [events, setEvents] = useState([]);
    const [page, setPage] = useState(1);
    const month = ["JAN","FEB","MAR","APR","MAY","JUN","JUL","AUG","SEP","OCT","NOV","DEC"];
    
    useEffect(() => {
      let isMounted = true;
      const controller = new AbortController();
      axiosPrivate.get(VIEW_EVENTS_URL + '?page=' + page + '&cat=' + cat).then((response)=>{
        if(isMounted){
            setEvents(response.data.docs);
            setTotalPages(response.data.pages);
        }
      }).catch((error) => {
        //Handle Errors Coming out of this
    });
      return () => { isMounted = false; controller.abort();  }
      // eslint-disable-next-line
    }, [page]);

    return (
        <>
            { events.length>0 &&
                <div className='contact-from mt-30 mb-60'>
                    <div className="section-title mb-5">
                        <h3>Past Events</h3>
                    </div> { /* <!-- section title --> */ }
                    { events.map((event) => {
                        return (
                            <div key={event.id} className="singel-feature mt-30">
                                <div className='cont'>
                                    <div className="row">
                                        <div className='col-2 pt-1 events-address'>
                                            <p className='px-3'>{ month[new Date(event.date).getMonth()]}</p>
                                            {(new Date(event.date).getDate())>9 ? 
                                                <p className='date-h2'> {new Date(event.date).getDate()}</p>
                                            :
                                                <p className='date-h2'> 0{new Date(event.date).getDate()}</p>
                                            }
                                            <p className='px-2'>{ new Date(event.date).getFullYear()}</p>
                                        </div>
                                        <div className="col-10 pt-2">
                                            <Link to={`/events/view/${event.id}`}><h5>{event.title}</h5></Link> &nbsp;&nbsp;
                                            {event.location && <p><i className="fa fa-map-marker"></i> {event.location}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })} {/** Past Events */}
                    {totalPages>1 &&
                        <div className="col-lg-12">
                            <Pagination totalPages={totalPages} setPage={setPage} page={page} />
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default PastEvents